<template>
  <div>
    <v-row
      v-for="clubReport in clubReports"
      :key="`club-report-${clubReport.id}`"
    >
      <v-col cols="12">
        <v-data-table
          v-if="clubReportResultsTables[clubReport.id]"
          :loading="loadingStatuses[clubReport.id]"
          :headers="clubReportResultsTables[clubReport.id].headers"
          :items="clubReportResultsTables[clubReport.id].items"
          :header-props="{ sortByText: 'ソート' }"
          no-data-text="データがありません"
          loading-text="読込中..."
          class="aggregation-table fixed-first-column"
          hide-default-footer
        >
          <template #top>
            <v-toolbar
              flat
              color="transparent"
            >
              <v-toolbar-title>{{ clubReport.attributes.name }}</v-toolbar-title>
              <v-spacer />
            </v-toolbar>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  ref,
  reactive,
  computed,
  toRefs,
  onMounted,
} from '@vue/composition-api'
import ClubReportApi from '@/api/v2/eod-report/ClubReport'

export default {
  props: {
    date: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { date } = toRefs(props)
    const clubReports = ref([])
    const clubReportResultsMap = ref({})
    const loadingStatuses = reactive({})

    const clubReportResultsTables = computed(() => {
      return clubReports.value.reduce((acc, clubReport) => {
        const businessRulesData = [...clubReport.attributes.businessRules.data].sort((a, b) => a.attributes.position - b.attributes.position)
        const headers = businessRulesData.map(br => ({
          text: br.attributes.name,
          value: br.attributes.id.toString(),
        }))
        headers.unshift({
          text: '日付',
          value: 'date',
          class: 'fixed-cell',
          cellClass: 'fixed-cell',
        })

        const items = clubReportResultsMap.value[clubReport.id] || []
        const flattenedItems = items.map(row => row[0])

        acc[clubReport.id] = { headers, items: flattenedItems }

        return acc
      }, {})
    })

    const getClubReports = async () => {
      const res = await ClubReportApi.getClubReports()

      if (res.data.status === 'success') {
        const { data } = res.data.data
        clubReports.value = data
        data.forEach(async cr => {
          Vue.set(loadingStatuses, cr.attributes.id, false)
        })
      }
    }

    const getMonthlyDailyResults = async id => {
      try {
        Vue.set(loadingStatuses, id, true)
        const res = await ClubReportApi.getMonthlyDailyResults(id, date.value)

        if (res.data.status === 'success') {
          clubReportResultsMap.value = { ...clubReportResultsMap.value, [id]: res.data.data }
        }
      } finally {
        Vue.set(loadingStatuses, id, false)
      }
    }

    onMounted(async () => {
      await getClubReports()
      Promise.all(clubReports.value.map(clubReport => getMonthlyDailyResults(clubReport.id)))
    })

    return {
      loadingStatuses,
      clubReports,
      clubReportResultsMap,
      clubReportResultsTables,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

@include theme--child(aggregation-table) using ($material) {
  th.fixed-cell,
  td.fixed-cell {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 3;
    background: map-deep-get($material, 'app-bar');
  }

  th.fixed-cell {
    z-index: 4;
  }

  .v-progress-linear {
    z-index: 5;
  }
}

.aggregation-table {
  white-space: nowrap;
}
</style>
