import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getClubReports() {
    return axiosIns.get(
      '/v2/eod_report/club_reports',
    )
  },
  getMonthlyDailyResults(id, date) {
    return axiosIns.get(
      `/v2/eod_report/club_reports/${id}/get_monthly_daily_results`,
      { params: { date } },
    )
  },
}
