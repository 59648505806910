<template>
  <v-data-table
    :loading="loading"
    :headers="aggregationTable.headers"
    :items="aggregationTable.items"
    :header-props="{ sortByText: 'ソート' }"
    :footer-props="{ itemsPerPageText: '行/ページ:' }"
    no-data-text="データがありません"
    loading-text="読込中..."
    :hide-default-footer="aggregationTable.items.length === 1"
    class="aggregation-table"
  >
    <template #top>
      <v-toolbar
        flat
        color="transparent"
      >
        <v-toolbar-title>{{ aggregationTable.name }}</v-toolbar-title>
        <v-spacer />

        <v-btn
          icon
          depressed
          :ripple="false"
          :loading="loading"
          :disabled="loading"
          @click.prevent="$emit('reload', aggregationTable.id)"
        >
          <v-icon>
            {{ icons.mdiReload }}
          </v-icon>
        </v-btn>
      </v-toolbar>
    </template>

    <!-- eslint-disable-next-line vue/no-useless-template-attributes -->
    <template
      v-for="header in aggregationTable.businessRuleHeaders"
      #[`item.${header.value}`]="{ item }"
    >
      <v-menu
        :key="`business-rule-result-input-for-current-club-business-rule-id-${header.value}`"
        :close-on-content-click="false"
        :nudge-width="200"
        :disabled="isNotEditable(item, header.value)"
        @input="onMenuInput($event, item, header.value)"
      >
        <template #activator="{ on: editMenuOn, attrs: editMenuAttrs }">
          <span
            v-bind="editMenuAttrs"
            v-on="editMenuOn"
          >
            <v-checkbox
              v-if="isBooleanItem(item, header.value)"
              :input-value="item[header.value]"
              :disabled="loading"
              readonly
              dense
              hide-details
            />
            <v-text-field
              v-else-if="isNumberItem(item, header.value)"
              :value="item[header.value].toLocaleString('ja-JP')"
              :disabled="loading"
              readonly
              dense
              hide-details
            />
            <v-text-field
              v-else
              :value="item[header.value]"
              :disabled="loading"
              :type="inputType(item, header.value)"
              readonly
              dense
              hide-details
            />
          </span>
        </template>
        <template #default="editMenu">
          <v-card>
            <v-card-subtitle>{{ header.text }}</v-card-subtitle>
            <v-card-text>
              <v-checkbox
                v-if="isBooleanItem(item, header.value)"
                v-model="item[header.value]"
                autofocus
                dense
                hide-details
                :disabled="isNotEditable(item, header.value)"
              />
              <v-text-field
                v-else
                v-model="item[header.value]"
                autofocus
                :type="inputType(item, header.value)"
                dense
                hide-details
                :disabled="isNotEditable(item, header.value)"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                text
                small
                @click="
                  editMenu.value = false;
                  item[header.value] = item.meta.originalValues[header.value];
                "
              >
                キャンセル
              </v-btn>
              <v-btn
                color="primary"
                small
                :disabled="isNotEditable(item, header.value)"
                @click="
                  editMenu.value = false;
                  saveResultValue(item, header.value, item[header.value]);
                "
              >
                保存
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-menu>
    </template>
  </v-data-table>
</template>

<script>
import { computed, toRefs } from '@vue/composition-api'
import { mdiReload } from '@mdi/js'
import { judgeType, interpretValue } from '@/utils/typeUtils'

export default {
  props: {
    aggregation: {
      type: Object,
      default: () => {
        return {
          attributes: {
            id: null,
            name: '',
            description: '',
            businessRules: { data: [] },
            results: { data: [] },
            contexts: { data: [{ attributes: { results: { data: [] } } }] },
          },
        }
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { aggregation, loading } = toRefs(props)

    const isBooleanItem = computed(() => (item, businessRuleId) => item.meta.inputTypes[businessRuleId] === 'boolean')
    const isNumberItem = computed(() => (item, businessRuleId) => item.meta.inputTypes[businessRuleId] === 'number')
    const isNotEditable = computed(() => (item, businessRuleId) => loading.value || !item.meta.editable[businessRuleId])
    const inputType = computed(() => (item, businessRuleId) => item.meta.inputTypes[businessRuleId])
    const numberText = computed(() => (item, businessRuleId) => (item[businessRuleId] ? item[businessRuleId].toLocaleString('ja-JP') : ''))

    /**
     * テーブルのヘッダーを構築する関数
     *
     * type BusinessRuleId = number
     *
     * interface BusinessRuleData {
     *   id: string;
     *   type: string;
     *   attributes: {
     *     id: BusinessRuleId;
     *     name: string;
     *     description: string | null;
     *     dependentBusinessRuleIds?: BusinessRuleId[]
     *   };
     * };
     *
     * interface Header { text: string; value: string; }
     * @param {Object} BusinessRuleData[]
     * @returns {Object} Header[]
     */
    const buildTableHeaders = businessRulesData => {
      return businessRulesData.map(data => {
        return {
          text: data.attributes.name,
          value: data.attributes.id,
          // autoにしてもcalculate-widthsを指定してもコンテンツに応じて幅がセットされないので一旦キメで
          maxWidth: '160px',
          minWidth: '160px',
          width: '160px',
        }
      })
    }

    /**
     * テーブルのアイテムデータを構築する関数
     *
     * type BusinessRuleExecutionResult = number | string | boolean | null
     * type BusinessRuleResultId = number
     * type BusinessRuleId = number
     * type DependentBusinessRuleId = number
     *
     * interface ResultData {
     *   id: string;
     *   type: string
     *   attributes: {
     *     id: string;
     *     businessRuleId: number;
     *     businessRuleResultId: BusinessRuleResultId | null
     *     value: string;
     *     dependentResources: { [resourceType: string]: number[] };
     *     dependentBusinessRuleIds: DependentBusinessRuleId[];
     *     editable: boolean;
     *   };
     * };
     *
     * interface Item {
     *  id: number;
     *  name: string;
     *  [key: BusinessRuleId]: BusinessRuleExecutionResult;
     *  meta: {
     *    businessRuleResultIds: { [key: BusinessRuleId]: BusinessRuleResultId };
     *    dependentResources: { [key: BusinessRuleId]: { [resourceType: string]: number[] } };
     *    dependentBusinessRuleIds: { [key: BusinessRuleId]: DependentBusinessRuleId[] };
     *    editable: { [key: BusinessRuleId]: boolean };
     *    originalValues: { [key: BusinessRuleId]: BusinessRuleExecutionResult };
     *  }
     * }
     * @param {Object} ResultData[]
     * @returns {Object} Item
     */
    const buildTableItem = resultsData => {
      const item = {
        meta: {
          businessRuleResultIds: {},
          dependentResources: {},
          dependentBusinessRuleIds: {},
          editable: {},
          _originalValues: {}, // 初期化時の値を保存
          _inputTypes: {}, // 初期化時の値を保存
          get originalValues() { // 初期化時の値を読み取り専用で返す
            return { ...this._originalValues }
          },
          get inputTypes() { // 初期化時の値を読み取り専用で返す
            return { ...this._inputTypes }
          },
        },
      }

      resultsData.forEach(result => {
        const {
          businessRuleId,
          businessRuleResultId,
          value,
          dependentResources,
          dependentBusinessRuleIds,
          editable,
        } = result.attributes

        const interpretedValue = interpretValue(value)

        item[businessRuleId] = interpretedValue
        item.meta.businessRuleResultIds[businessRuleId] = businessRuleResultId
        item.meta.dependentResources[businessRuleId] = dependentResources
        item.meta.dependentBusinessRuleIds[businessRuleId] = dependentBusinessRuleIds
        item.meta.editable[businessRuleId] = editable

        // originalValues と inputTypes に初期値を設定
        item.meta._originalValues[businessRuleId] = interpretedValue
        item.meta._inputTypes[businessRuleId] = judgeType(value)
      })

      return item
    }

    const aggregationTable = computed(() => {
      const {
        id,
        name,
        description,
        businessRules,
        contexts,
        type,
      } = aggregation.value.attributes

      const businessRuleHeaders = buildTableHeaders(businessRules.data)
      const items = []
      const headers = [...businessRuleHeaders]

      contexts.data.forEach(context => {
        const {
          contextType,
          id: contextId,
          name: contextName,
          results,
        } = context.attributes

        items.push({
          contextType,
          id: contextId,
          name: contextName,
          ...buildTableItem(results.data),
        })
      })

      return {
        id,
        name,
        description,
        businessRuleHeaders,
        headers,
        items,
      }
    })

    const saveResultValue = (item, businessRuleId, resultValue) => {
      const { id: contextId, contextType } = item
      const originalValue = item.meta.originalValues[businessRuleId]
      const hasNotChanged = interpretValue(originalValue) === interpretValue(resultValue)

      emit('save', {
        aggregationId: aggregation.value.attributes.id,
        contextId,
        contextType,
        businessRuleId,
        resultValue: hasNotChanged ? null : resultValue, // nullで送って計算はサーバー側で行うので
      })
    }

    const onMenuInput = (open, item, businessRuleId) => {
      if (!open) {
        // eslint-disable-next-line no-param-reassign
        item[businessRuleId] = item.meta.originalValues[businessRuleId]
      }
    }

    return {
      isBooleanItem,
      isNumberItem,
      numberText,
      isNotEditable,
      inputType,
      aggregationTable,
      saveResultValue,
      onMenuInput,
      icons: {
        mdiReload,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

.aggregation-table {
  white-space: nowrap;
}
</style>
